import React, { Component } from "react"

import NavElement from './navElement'
import NavLinkElement from './navLinkElement'
import NavCVElement from './navCVElement'

class Nav extends Component {

    render() {
        return(
            <div style={navStyle}>
                <NavLinkElement delay = '0' text = 'About' destination = '/about' windowWidth = {this.props.windowWidth} />
                <NavElement delay = '150' text = 'Books' scrollDestination = '#books' windowWidth = {this.props.windowWidth} />
                <NavElement delay = '300' text = 'Research' scrollDestination = '#research' windowWidth = {this.props.windowWidth} />
                <NavElement delay = '450' text = 'My Podcasts' scrollDestination = '#podcast' windowWidth = {this.props.windowWidth} />
                <NavElement delay = '600' text = 'In The Media' scrollDestination = '#media' windowWidth = {this.props.windowWidth} />
                <NavLinkElement delay = '750' text = 'Data' destination = '/data' windowWidth = {this.props.windowWidth} />
                <NavCVElement delay = '900' text = 'CV' windowWidth = {this.props.windowWidth} />
            </div>
        )
    }
}

const navStyle = {
    maxWidth: '820px',
    margin: '2px auto 0px auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    paddingTop: '8px',
    color: '#444',
}

export default Nav;