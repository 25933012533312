import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Nav from "./nav/nav"
import NavNonRoot from './nav/navNonRoot'

import './typography.css'

function RenderNav({rootPage, windowWidth}) {
  if (rootPage) {
    return(
      <Nav windowWidth = {windowWidth} />
    )
  } else {
    return(
      <NavNonRoot windowWidth = {windowWidth} />
    )
  }
}

function RenderHeader({siteTitle, rootPage, windowWidth}) {

    if (windowWidth >= 500) {
      return(
        <div style = {headerWrapper}>
        <header style = {headerStyle}  >
          <div style = {titleWrapperStyle}>
            <h1 style = {titleText}>
              <Link
                to="/"
                style={{
                  color: `black`,
                  textDecoration: `none`,
                }}
              >
                {siteTitle}
              </Link>
            </h1>
          </div>
        </header>
        <RenderNav rootPage = {rootPage} windowWidth = {windowWidth} />
        </div>
      )
    } else {
      return(
        <div style = {headerWrapper}>
        <header style = {headerStyle}  >
          <div style = {titleWrapperStyle}>
            <h1 style = {titleTextMobile}>
              <Link
                to="/"
                style={{
                  color: `black`,
                  textDecoration: `none`,
                }}
              >
                {siteTitle}
              </Link>
            </h1>
          </div>
        </header>
        <RenderNav rootPage = {rootPage} windowWidth = {windowWidth} />
        </div>
      )
    }
}

const Header = ({ siteTitle, rootPage, windowWidth }) => (
  <RenderHeader siteTitle = {siteTitle} rootPage = {rootPage} windowWidth = {windowWidth} />
)

const headerWrapper = {
  
}

const headerStyle = {
  width: '100%',
}

const titleWrapperStyle = {
  width: '100%',
  margin: '44px auto 0px auto',
  textAlign: 'center',
}

const titleText = {
  fontFamily: 'Avant Garde, sans-serif',
  fontSize: '46px',
  lineHeight: '50px',
  marginBottom: '0px',
}

const titleTextMobile = {
  ...titleText,
  fontSize: '36px',
  lineHeight: '36px'
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
