import React, { Component, createRef } from "react"

import { Spring } from 'react-spring/renderprops'

import scrollTo from 'gatsby-plugin-smoothscroll';

class NavElement extends Component {

    constructor() {
        super();

        this.callScrollFunction = this.callScrollFunction.bind(this);
        this.mouseDidEnter = this.mouseDidEnter.bind(this);
        this.mouseDidExit = this.mouseDidExit.bind(this);
    }

    mouseDidEnter(e) {
        e.target.style.color = '#666'
    }

    mouseDidExit(e) {
        e.target.style.color = 'black'
    }

    callScrollFunction() {
        scrollTo(this.props.scrollDestination)
    }

    render() {
        if (this.props.windowWidth > 500) {
            return(
                <Spring
                    from={{ ...navElementStyle, bottom: '-10px', opacity: 0 }}
                    to={{ ...navElementStyle, bottom: '0px', opacity: 1 }}
                    delay={this.props.delay}>
                    { props => 
                    <div style={props} onClick = {this.callScrollFunction} onMouseEnter = {this.mouseDidEnter} onMouseLeave = {this.mouseDidExit}>
                        {this.props.text}
                    </div>
                    }
                </Spring>
            )
        } else {
            return(
                <Spring
                    from={{ ...navElementStyleMobile, bottom: '-10px', opacity: 0 }}
                    to={{ ...navElementStyleMobile, bottom: '0px', opacity: 1 }}
                    delay={this.props.delay}>
                    { props => 
                    <div style={props} onClick = {this.callScrollFunction} onMouseEnter = {this.mouseDidEnter} onMouseLeave = {this.mouseDidExit}>
                        {this.props.text}
                    </div>
                    }
                </Spring>
            )
        }  
    }
}

const navElementStyle = {
    fontFamily: 'Avant Garde',
    fontSize: '22px',
    position: 'relative',
    bottom: '0px',
    cursor: 'pointer',
    margin: '0px 10px 10px 10px'
}

const navElementStyleMobile = {
    ...navElementStyle,
    fontSize: '18px',
    marginBottom: '4px'
}

export default NavElement;